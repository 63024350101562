import { Container } from "../components";
import { LoginLeftPanel } from "src/components/login-left-panel";
import { UpdatePasswordPanel } from "src/components/update-password-panel";

export const UpdatePassword = (props: any) => {
  return (
    <Container showNav={false}>
      <div className="flex flex-col lg:flex-row">
        {/* Left Col - Banner, change lang & desc */}
        <LoginLeftPanel />

        {/* Right Col - CUpdate Password */}
        <UpdatePasswordPanel />
      </div>
    </Container>
  );
};
