import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { openWarningNotification } from "src/components";
import { useCryptoAssetStore, useUserStore } from "src/stores";
import { ICryptoBalanceProps, ICryptoHandlingFeesProps } from "src/type/crypto";
import { ITransactionsBase, ITxnFilterProps } from "src/type/transaction";

export const useCryptoAssetHookStore = () => {
  const { t } = useTranslation();
  const [activeIdx, setActiveIdx] = useState<number>(0);
  const [totalBalanceInUsd, setTotalBalanceInUsd] = useState<number | null>(
    null
  );
  const [totalBalanceInHkd, setTotalBalanceInHkd] = useState<number | null>(
    null
  );
  const [walletTokenList, setWalletTokenList] = useState<
    Array<ICryptoBalanceProps>
  >([]);

  // Withdraw
  const [tokenBalance, setTokenBalance] = useState<number>(0);
  const [assetType, setAssetType] = useState<string>(t("please_select"));
  const [amount, setAmount] = useState<number>(0);
  const [toAddress, setToAddress] = useState<string>("");
  const [handlingFees, setHandlingFees] = useState<ICryptoHandlingFeesProps>({
    handlingFee: 0,
    currency: "",
  });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  // Deposit
  const [network, setNetwork] = useState<string>("");
  const [depositAddress, setDepositAddress] = useState<string>("");
  const [token, setToken] = useState<string>(t("select_token") || "");

  const [transactions, setTransactions] =
    useState<Array<ITransactionsBase> | null>(null);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);

  const { getCryptoAccount, getCryptoFees, withdrawCrypto } =
    useCryptoAssetStore();
  const { getTransactions, updatePendingTransaction } = useUserStore();

  const getTransactionsCb = useCallback(
    async (filter: ITxnFilterProps) => {
      let newTransactionData: Array<ITransactionsBase> = [];
      const result = await getTransactions(filter || {});
      if (!result) return setTransactions([]);
      newTransactionData = result;
      // TODO: Test Get Pending Transaction
      const pendingTransaction = await updatePendingTransaction();
      if (!pendingTransaction) return setTransactions(result);
      newTransactionData = [...newTransactionData, ...pendingTransaction];
      setTransactions(newTransactionData);
    },
    [getTransactions, updatePendingTransaction]
  );

  const getCryptoFeesCb = useCallback(async () => {
    if (!assetType) return;
    setIsFetching(true);
    const result = await getCryptoFees(`?asset=${assetType}`);
    setIsFetching(false);
    if (!result)
      return openWarningNotification(t("message.get_crypto_fees_failed"));
    setHandlingFees(result);
  }, [assetType, getCryptoFees, t]);

  useEffect(() => {
    // Get all Crypto Transactions
    getTransactionsCb({
      status: "crypto_all",
      type: "crypto_all",
      limit: 0,
    });
    getCryptoAccount().then((result) => {
      if (!result || result === "user_crypto_account_not_exist")
        return setActiveIdx(-1);

      const { balances, totalBalanceInUsd, totalBalanceInHkd } = result;
      setTotalBalanceInUsd(totalBalanceInUsd);
      setTotalBalanceInHkd(totalBalanceInHkd);

      let newBalanceList: Array<ICryptoBalanceProps> = [];
      balances.forEach((balance: ICryptoBalanceProps) => {
        let label = "";
        let value = "";
        if (!balance.blockchain) return;

        if (balance.blockchain === "ETHEREUM") {
          value = `${balance.asset}_ERC`;
          label = `${balance.asset} (ETHEREUM)`;
        }
        if (balance.blockchain === "TRON") {
          value = `${balance.asset}_TRC`;
          label = `${balance.asset} (TRON)`;
        }
        balance.value = value;
        balance.label = label;

        newBalanceList.push({ ...balance, value, label });
      });
      setWalletTokenList(newBalanceList);

      // Each Token Balance
      if (assetType) {
        const asset = balances.find(
          (item: ICryptoBalanceProps) => item?.value === assetType
        );
        setTokenBalance(asset?.balance ? Number(asset?.balance) : 0); // Token Balance
      }
    });
  }, [
    assetType,
    getTransactionsCb,
    getCryptoAccount,
    setActiveIdx,
    setTokenBalance,
  ]);

  useEffect(() => {
    if (!transactions) return;
    setAllLoaded(true);
  }, [transactions, setAllLoaded, getTransactionsCb]);

  return {
    activeIdx,
    setActiveIdx,
    // Balance
    totalBalanceInUsd,
    totalBalanceInHkd,
    walletTokenList,
    //Withdraw
    tokenBalance,
    setTokenBalance,
    assetType,
    setAssetType,
    amount,
    setAmount,
    toAddress,
    setToAddress,
    handlingFees,
    modalOpen,
    setModalOpen,
    isLoading,
    setIsLoading,
    isFetching,
    // Deposit
    network,
    setNetwork,
    depositAddress,
    setDepositAddress,
    token,
    setToken,
    // Transaction
    transactions,
    allLoaded,
    // Store
    getTransactionsCb,
    getCryptoFeesCb,
    withdrawCrypto,
  };
};
