import { LockOutlined } from "@ant-design/icons";
import { LoadingIcon } from "./loading-icon";
import { useTranslation } from "react-i18next";

export const MasterCardPanel = (props: any) => {
  const { t } = useTranslation();
  const {
    disabled,
    cardStatus,
    setAction,
    setIsModalOpen,
    setShowForm,
    isUpdating,
  } = props;

  const cardActiveState =
  cardStatus && Number(cardStatus) === 5
    ? "unlock_card"
    : "lock_card"

  const handleOnClick = (cardState: string) => {
    cardState && setAction(cardState);
    setIsModalOpen(true);
  };

  return (
    <div className="w-full lg:w-[545px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <div>
        <h3 className="normal-case">{t("masterCard.my_card")}</h3>
      </div>
      <div className="flex flex-row mt-[20px]">
        {/* Sample Card */}
        <div className="ml=0 lg:ml-[20px] flex-1">
          <div className="overflow-hidden h-[158px]">
            <img
              src={"/assets/Rich_Dragon_MasterCard.png"}
              className="w-[250px]"
              alt="sample-card"
            />
             {/* Overlay Bg */}
           {cardStatus && (
              <div
                className={`${
                  Number(cardStatus) === 5
                    ? "bg-[#00000080] relative bottom-[158px] w-[250px] h-[158px] rounded-[10px]"
                    : ""
                }`}
              >
                <div className="flex flex-1 h-full w-full justify-center items-center text-white">
                  {isUpdating && <LoadingIcon size={"40"} color="white" />}
                  {!isUpdating && Number(cardStatus) === 5 ? (
                    <LockOutlined
                      className="text-[20px]"
                      onClick={() => handleOnClick(cardActiveState)}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>
          
        </div>

        {/* Card Info */}
        <div className="ml-[20px] flex-1">
          <div className="flex flex-row flex-wrap">
            <button className={$btn} onClick={() => setShowForm("card-info")}>
              {t("button.card_info")}
            </button>
            <button className={$btn} onClick={() => setShowForm("top-up")}>
              {t("button.top_up_master_card")}
            </button>
            <button
              disabled={disabled}
              className={disabled ? $disabledBtn : $btn}
              onClick={() => setShowForm("edit-pin")}
            >
              {t("button.edit_atm_pin")}
            </button>
            <button
              className={disabled ? $disabledBtn : $btn}
              onClick={() => setShowForm("edit-daily-limit")}
              disabled={disabled}
            >
              {t("button.edit_daily_limit")}
            </button>
            <button
              className={disabled ? $disabledBtn : $btn}
              onClick={() => handleOnClick(cardActiveState)}
              disabled={disabled}
            >
              {/* e.g. 鎖卡/解鎖 */}
              {t(`button.${cardActiveState}`)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const $btn =
  "bg-gradient-to-r from-[#F3E1A3] to-[#E3B354] w-full text-black font-[700] py-[5px] mb-[10px] text-[10px] lg:text-[12px]";
const $disabledBtn =
  "bg-[#3b3b3b4d] w-full text-black font-[700] py-[5px] text-[10px] lg:text-[12px]";
