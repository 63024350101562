import {
  CreditCardOutlined,
  TransactionOutlined,
  MoneyCollectOutlined,
  WalletOutlined,
} from "@ant-design/icons";
const $labelIcon = "text-[30px] !text-[#835506]";

export const BASIC_FUNCTION_LIST = [
  {
    name: "main_menu_item.onshore_wallet",
    icon: <WalletOutlined className={$labelIcon} />,
    desc: "dashboard.onshore_desc",
    page: "/wallet",
  },
  {
    name: "crypto_asset.crypto_asset_label",
    icon: <MoneyCollectOutlined className={$labelIcon} />,
    desc: "crypto_asset.crypto_asset_summary",
    page: "/crypto-asset",
  },
  {
    name: "convert",
    icon: <TransactionOutlined className={$labelIcon} />,
    desc: "dashboard.convert_desc",
    page: "/convert",
  },
  {
    name: "main_menu_item.master_card",
    icon: <CreditCardOutlined className={$labelIcon} />,
    desc: "dashboard.card_desc",
    page: "/master-card",
  },
];
