export const capitalised = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const padZerosBehind = (amount: any, dp: number) => {
  return String(amount * 10 ** dp);
};

export function getColor(txt: string) {
  switch (txt) {
    case "done":
    case "succeed":
    case "posted":
      return "#389E0D";
    case "fail":
    case "void":
    case "declined":
      return "#F5423E";
    default:
      return "#E3B354";
  }
}

export function getStatusTxt(txt: string) {
  switch (txt) {
    case "done":
      return "成功";
    case "fail":
      return "失敗";
    default:
      return "處理中";
  }
}

export function getTypeTxt(txt: string) {
  switch (txt) {
    case "deposit":
      return "deposit";
    case "withdraw":
      return "withdraw";
    case "convert":
      return "convert";
    case "transfer-in":
      return "transfer_in";
    case "transfer-out":
      return "transfer_out";
    case "exchange":
      return "exchange";
    case "card":
    case "top-up-master-card":
      return "card";
    case "charge":
      return "charge";
    case "transfer":
      return "transfer";
    case "crypto_withdraw":
      return "crypto_withdraw";
    default:
      return txt;
  }
}

export function getAssetName(name: string) {
  if (name === "Ethereum") return "ETH";
  if (name === "Bitcoin") return "BTC";
  return name;
}

export const getTokenLabel = (name: string) => {
  switch (name) {
    case "USDT_ERC":
    case "USDT_TRC":
      return "USDT";
    case "USDC_ERC":
      return "USDC";
    case "HKD":
      return "HKD";
    case "USD":
      return "USD";
    default:
      break;
  }
};

export function getQueryParam(queryString?: string) {
  const urlSearchParams = new URLSearchParams(queryString);
  const queryObj = Object.fromEntries(urlSearchParams.entries());
  return queryObj;
}

export const formatDollar = (amount: any, withDollarSign?: false) => {
  amount = parseFloat(amount);
  const formatter = new Intl.NumberFormat(
    "en-US",
    withDollarSign
      ? {
          style: "currency",
          currency: "USD",
        }
      : {}
  );
  return formatter.format(amount);
};

export const isBase64UrlImage = (str: string) => {
  if (str === "" || str.trim() === "") {
    return false;
  }
  return str.includes("base64");
};
