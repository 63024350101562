import { ApplicationPanel } from "src/components/application-panel";
import { Container } from "../components";
import { LoginLeftPanel } from "src/components/login-left-panel";

export const Application = (props: any) => {
  return (
    <Container showNav={false}>
      <div className="flex flex-col lg:flex-row">
        {/* Left Col - Banner, change lang & desc */}
        <LoginLeftPanel />

        {/* Application Form */}
        <ApplicationPanel />
      </div>
    </Container>
  );
};
