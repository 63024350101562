import { t } from "i18next";
import { ReactNode } from "react";
import { LoadingIcon } from "./loading-icon";
import { QrCanvas } from "./qr-canvas";

interface IPaymentQr {
  isLoading: boolean;
  method: string;
  paymentCode: string;
  extra?: ReactNode;
}

export const PaymentQr = (props: IPaymentQr) => {
  const { isLoading, method, paymentCode, extra } = props;

  return (
    <div className="h-full">
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col flex-1 justify-center items-center rounded-xs mx-auto ">
          {isLoading && (
            <div className="w-[250px] h-[250px] flex justify-center items-center">
              <LoadingIcon size={"35"} color="#E3B354" />
            </div>
          )}
          {!isLoading && paymentCode && (
            <QrCanvas method={method} paymentCode={paymentCode} />
          )}
        </div>

        {paymentCode && method && (
          <>
            <p
              className="mt-s"
              dangerouslySetInnerHTML={{
                __html: t("onshore_wallet.deposit_payment_qr", {
                  method: t(`onshore_wallet.${method}`),
                }),
              }}
            ></p>
            <p className={"text-xs!text-note mt-xs"}>
              {t("onshore_wallet.deposit_declaration")}
            </p>
          </>
        )}
        {extra}
      </div>
    </div>
  );
};
