import { useCallback, useEffect, useState } from "react";
import {
  Container,
  LoadingIcon,
  openSuccessNotification,
  openWarningNotification,
} from "src/components";
import { Auth } from "src/components/auth";
import { useUserStore } from "src/stores";
import { UpdateProfileAccountCenter } from "../components/update-profile-update-profile-account-center";
import { UpdateProfilePersonalInfo } from "../components/update-profile-personal-info";
import { ModalEditProfileForm } from "src/components/modal-edit-profile-form";
import { useTranslation } from "react-i18next";
const md5 = require("md5");

export const UpdateProfile = (props: any) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<any | null>(null);
  const [actionList, setActionList] = useState([]);
  const [displayName, setDisplayName] = useState<string>("");
  const [newDisplayName, setNewDisplayName] = useState<string>("");
  const [password, setPassword] = useState<string | null>("");
  const [newPassword, setNewPassword] = useState<string | null>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string | null>(
    ""
  );
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allLoaded, setAllLoaded] = useState<boolean>(false);
  const { getProfileV2, updatePassword, updateProfile } = useUserStore();

  const getProfileCb = useCallback(async () => {
    getProfileV2(`?type=full`).then((data) => {
      if (!data) return;
      setUser(data);
      setDisplayName(data.display_name);
    });
  }, [getProfileV2, setUser, setDisplayName]);

  useEffect(() => {
    // User Profile
    getProfileCb();
  }, [getProfileCb]);

  useEffect(() => {
    if (!user) return;
    setAllLoaded(true);
  }, [user, setAllLoaded]);

  const handleConfirmDisplayNameUpdate = async (type: string) => {
    if (!newDisplayName)
      return openWarningNotification(t("message.please_fill_in_display_name"));
    setIsLoading(true);
    const result = await updateProfile({
      name: type,
      value: newDisplayName,
    });
    setIsLoading(false);
    if (!result || typeof result !== "boolean")
      return openWarningNotification(t("message.update_failed"));
    openSuccessNotification(t("message.update_success"));
    getProfileCb();
    // Reset Form
    setNewDisplayName("");
    setIsModalOpen(false);
    setActionList([]);
    return;
  };

  const handleConfirmPasswordUpdate = async () => {
    // Validate Form
    if (!password)
      return openWarningNotification(t("message.please_fill_in_display_name"));
    if (!newPassword)
      return openWarningNotification(t("message.please_fill_in_new_password"));
    if (!confirmNewPassword)
      return openWarningNotification(t("message.please_fill_in_new_password"));
    if (password === newPassword)
      return openWarningNotification(t("message.please_fill_in_new_password"));
    if (newPassword !== confirmNewPassword)
      return openWarningNotification(t("message.password_not_match"));
    setIsLoading(true);
    const result = await updatePassword({
      password: md5(password),
      newPassword: md5(confirmNewPassword),
    });
    setIsLoading(false);
    if (!result || typeof result !== "boolean")
      return openWarningNotification(t("message.update_failed"));
    openSuccessNotification(t("message.update_success"));
    // Reset Form
    setPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setActionList([]);
    setIsModalOpen(false);
    return;
  };

  const handleOnModalConfirm = async (type: string) => {
    if (!type) return "";
    type === "display_name" && (await handleConfirmDisplayNameUpdate(type));
    type === "password" && (await handleConfirmPasswordUpdate());
  };

  const handleModalClose = () => {
    setActionList([]);
    setIsModalOpen(false);
    return;
  };

  return (
    <Auth>
      <Container showNav={true} showMenu={true} scroll={true}>
        {!allLoaded && (
          <div className="flex flex-1 h-screen w-full justify-center items-center">
            <LoadingIcon color="#E3B354" />
          </div>
        )}

        {allLoaded && (
          <div className="flex flex-col lg:flex-row p-[20px] lg:p-[30px] lg:pt-[100px] w-full">
            {/* Account Center */}
            <div>
              <UpdateProfileAccountCenter
                displayName={displayName}
                actionList={actionList}
                setIsModalOpen={setIsModalOpen}
                setActionList={setActionList}
              />
            </div>

            {/* User Profile Info */}
            <div className="ml-[0px] mt-[20px] lg:ml-[20px] lg:mt-[0px]">
              <UpdateProfilePersonalInfo user={user} />
            </div>

            {/* Modal */}
            {actionList.length > 0 &&
              actionList.map((item, i) => {
                return (
                  <div key={i}>
                    <ModalEditProfileForm
                      formType={item}
                      isLoading={isLoading}
                      actionList={actionList}
                      newDisplayName={newDisplayName}
                      setNewDisplayName={setNewDisplayName}
                      password={password}
                      setPassword={setPassword}
                      newPassword={newPassword}
                      setNewPassword={setNewPassword}
                      confirmNewPassword={confirmNewPassword}
                      setConfirmNewPassword={setConfirmNewPassword}
                      modalOpen={modalOpen}
                      handleClose={() => handleModalClose()}
                      handleOnModalConfirm={handleOnModalConfirm}
                    />
                  </div>
                );
              })}
          </div>
        )}
      </Container>
    </Auth>
  );
};