import { useState } from "react";
import { getColor, getCurrencyIcon } from "src/utils";
import TransactionRow from "./transaction-row";
import { LoadingIcon } from "./loading-icon";
import { TransactionFilterButtons } from "./transaction-filter-buttons";
import { MasterCardTransactionFilterButtons } from "./master-card-transaction-filter-buttons";
import { openWarningNotification } from "./notification";
import { useTranslation } from "react-i18next";

export const TransactionList = (props: any) => {
  const { t } = useTranslation();

  const {
    txnType,
    setTxnType,
    transactions,
    getTransactionsCb,
    setTransactions,
    type,
    cardNumber,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
  } = props;

  const [isSpin, setIsSpin] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleViewAll = async (tableType: string) => {
    let tnxStatus = "all";
    let tnxType = type;

    switch (tableType) {
      case "crypto":
        tnxStatus = "crypto_all";
        break;
      case "onshore":
        tnxType = "onshore";
        break;
      default:
        break;
    }
    setIsLoading(true);
    await getTransactionsCb({
      status: tnxStatus,
      type: tnxType,
      limit: 0,
    });
    setIsLoading(false);
    return;
  };

  const handleSearch = async (sDate: string, eDate: string) => {
    if (!sDate || !eDate)
      return openWarningNotification(t("message.missing_dates"));

    setIsLoading(true);
    setIsFetching(true);
    const result = await getTransactionsCb({
      startDate: sDate,
      endDate: eDate,
    });
    setIsLoading(false); // Transaction Loader
    setIsFetching(false); // Search Loader
    if (!result) return;
  };

  const getButtonGpComponent = (type: string) => {
    if (type === "convert") return null;
    if (type === "master-card")
      return (
        <MasterCardTransactionFilterButtons
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          getTransactionsCb={getTransactionsCb}
          disabledRangePicker={!cardNumber || isFetching || isLoading || isSpin}
          disabledButton={!cardNumber || isFetching || isLoading || isSpin}
          isFetching={isFetching}
          isSpin={isSpin}
          setIsLoading={setIsLoading}
          setIsSpin={setIsSpin}
          onSearchClick={() => handleSearch(startDate, endDate)}
        />
      );
    return (
      <TransactionFilterButtons
        fromPage={type}
        setTransactions={setTransactions}
        setIsLoading={setIsLoading}
        getTransactionsCb={getTransactionsCb}
        txnType={txnType}
        setTxnType={setTxnType}
      />
    );
  };

  const showViewAll = (type: string) => {
    if (!type) return false;
    if (type === "master-card") return false;
    if (type === "convert") return false;
    return true;
  };

  return (
    <div className="w-full lg:w-[545px] min-h-[100px] bg-white rounded-m drop-shadow p-m">
      <div className="flex justify-between">
        <div className="flex-1">
          <h3 className="normal-case">
            {t("transaction.recent_transaction_history")}
          </h3>
        </div>

        {showViewAll(type) && (
          <p
            className="text-primary cursor-pointer"
            onClick={() => handleViewAll(type)}
          >
            {t("transaction.view_all_transactions")}
          </p>
        )}
      </div>

      {getButtonGpComponent(type)}

      {/* Transactions */}
      <div className="mt-m">
        {isLoading && (
          <div className="min-h-[380px] flex flex-row justify-center items-center">
            <LoadingIcon color="#D41E44" />
          </div>
        )}

        {/* No Transaction Record */}
        {!isLoading &&
          (!transactions || (transactions && transactions.length === 0)) && (
            <div className="min-h-[380px] flex flex-row justify-center items-center">
              <p>{t("transaction.no_transaction_record")}</p>
            </div>
          )}

        {!isLoading &&
          transactions &&
          transactions.length > 0 &&
          transactions.map((item: any, i: number) => {
            const {
              type,
              status = "succeed",
              amount,
              currency,
              created_at,
              bank_type,
              received_amount = null,
              received_currency = "",
            } = item;
            const txtColor = getColor(status);
            return (
              <div key={i}>
                <TransactionRow
                  getCurrencyIcon={getCurrencyIcon}
                  amount={amount}
                  currency={currency}
                  received_amount={received_amount}
                  received_currency={received_currency}
                  date={created_at}
                  status={status}
                  type={type}
                  bank_type={bank_type}
                  txtColor={txtColor}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
