import { useTranslation } from "react-i18next";
import { ChangeLangButton } from "./change-lang-button";
export const LoginLeftPanel = (props: any) => {
  const { t } = useTranslation();
  return (
    <div
      className="w-full lg:w-[50vw] min-w-[350px] min-h-[auto] lg:min-h-screen h-full relative p-[20px] lg:p-[5%] overflow-hidden flex flex-col"
      style={{
        backgroundImage: `url("/assets/bg.jpg")`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      {/* Overlay */}
      <div
        className={`bg-[#000]/50 absolute left-0 top-0 w-full h-full z-[10]`}
      />

      {/* Logo & Lang Selection */}
      <div className="hidden lg:flex flex-row relative z-[15] flex-1">
        <div className="flex items-start flex-1">
          <img
            src="/assets/rich_dragon_icon.png"
            className="w-[130px] object-contain"
            alt="rich-dragon-icon"
          />
        </div>

        {/* Language */}
        <ChangeLangButton
          from="login"
          width="100"
          btnStyle="border-[1px] border-[#CCCCCC]"
          prefix={
            <div className="pl-[10px]">
              <img src={"/assets/earth_white_color.svg"} alt="earth-icon" />
            </div>
          }
        />
      </div>

      {/* Title & Description  */}
      <div className="flex flex-col relative z-[15] w-full">
        <h2 className="normal-case text-white text-[30px]">{t("desc1")}</h2>
        <div className="mt-[30px]">
          <h4 className="text-white">
            {t("desc2")}
            <br></br>
            <br></br>
            {t("desc3")}
          </h4>
        </div>
      </div>
    </div>
  );
};
