import DonutChart from "react-donut-chart";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";

export const DashboardTxnStat = (props: any) => {
  const { t } = useTranslation();
  const { balance } = props;

  const {
    onshoreBalance = 0,
    cryptoBalance = 0,
    cardBalance = 0,
    securitiesBalance = 0,
  } = balance || {};
  const totalBalance =
    onshoreBalance + cryptoBalance + cardBalance + securitiesBalance;
  const otherBalances = cryptoBalance + cardBalance + securitiesBalance;
  const fiatPerc = (onshoreBalance / totalBalance) * 100 || 0;
  const otherPerc = (otherBalances / totalBalance) * 100 || 0;

  const [showEye, setShowEye] = useState(true);
  const size: any = useWindowSize();

  const hide = (isEye: boolean) => {
    if (isEye) return setShowEye(false);
    if (!isEye) return setShowEye(true);
  };

  return (
    <div className="w-full lg:w-[650px] min-h-[100px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <div className="flex justify-center items-center">
        <div className="flex-1">
          <h3 className="normal-case">
            {t("dashboard.total_asset_value")} (HKD)
          </h3>
        </div>

        <div
          className="flex justify-center items-center border w-[40px] h-[30px] rounded-[5px] drop-shadow cursor-pointer"
          onClick={() => hide(showEye)}
        >
          {showEye ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        </div>
      </div>

      <div className="mt-[5px]">
        <h2>{showEye ? Number(totalBalance).toFixed(2) : `******`}</h2>
      </div>

      <div className="flex flex-flex mt-[20px] justify-center items-center">
        <p className="pr-[20px] text-[13px]">{t("dashboard.fiat_assets")}</p>

        <p className="pr-[10px] text-[#274673] text-[13px] font-[600]">
          {fiatPerc.toFixed(0)}%
        </p>
        <div className="relative flex-1 border-[1px] w-full h-[20px] rounded-[10px]">
          <div
            className="absolute top-0 left-0 bg-gradient-to-r from-[#F3E1A3] to-[#E3B354] h-[20px] rounded-tl-[10px] rounded-bl-[10px]"
            style={{
              width: `${fiatPerc}%`,
            }}
          />
        </div>

        <p className="pl-[10px] text-[13px] font-[600] text-primary">
          {otherPerc.toFixed(0)}%
        </p>
        <p className="pl-[20px] text-[13px]">{t("dashboard.other_assets")}</p>
      </div>

      {/* Donut Chart */}
      <div className="rd-donut-chart mt-[20px] bg-gradient-to-b from-[#f1f1f1] to-[#fff] p-[20px] rounded-[10px]">
        <h4 className="normal-case">{t("dashboard.asset_distribution")}</h4>
        <div className="mt-[10px]">
          <DonutChart
            width={size?.width < 800 ? 300 : 600}
            height={size?.width < 800 ? 300 : 500}
            innerRadius={0.65}
            outerRadius={0.3}
            strokeColor={"#fff"}
            colors={["#DC9B32", "#FFC45F", "#FFE3B3"]}
            formatValues={(values: any, total: any) =>
              `${((values / total || 0) * 100).toFixed(2)} %`
            }
            legend={size?.width < 800 ? false : true}
            data={[
              {
                label: t("dashboard.fiat_account_local_hkd"),
                value: onshoreBalance,
              },
              {
                label: t("dashboard.fiat_account_overseas_usd"),
                value: 0,
              },
              {
                label: t("dashboard.digital_assets"),
                value: cryptoBalance,
              },
              {
                label: t("dashboard.card_balance"),
                value: cardBalance,
              },
              {
                label: t("dashboard.investment_account"),
                value: securitiesBalance,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
