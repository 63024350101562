import { getCurrencyIcon } from "src/utils";
import { Button } from "./button";
import { CURRENCY_LIST } from "src/config/currency";
import { useTranslation } from "react-i18next";

export const TransferOutPanel = (props: any) => {
  const { t } = useTranslation();
  const { currency, handleCurrencyClick } = props;
  return (
    <div className="w-full lg:w-[650px] min-h-[100px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <h3 className="normal-case">{t("transfer_out.select_currency")}</h3>

      <div className="flex mt-[20px] border-primary justify-start items-center flex-wrap">
        {CURRENCY_LIST.map((opt, i: number) => {
          const acceptedCurrency: any = ["HKD", "RMB", "USD", "EUR"];
          const disabled = acceptedCurrency.indexOf(opt) < 0;
          return (
            <div className="mr-[15px] mb-[15px]" key={i}>
              <Button
                value={opt}
                btnStyle={`${
                  currency === opt ? "border-2 border-primary" : "bg-[#eee]"
                }
                ${disabled ? "cursor-not-allowed" : "cursor-pointer"}
                `}
                height="38"
                prefix={
                  <div className="overflow-hidden border rounded-[50px]">
                    <img
                      src={getCurrencyIcon(opt)}
                      alt="currency"
                      className="w-[25px] h-[25px] object-cover"
                    />
                  </div>
                }
                handleOnClick={() => handleCurrencyClick(opt)}
                disabled={disabled}
              />
            </div>
          );
        })}
      </div>
      <div className="mt-[5px]">
        <button className={$btn}>{t("button.confirm")}</button>
      </div>
    </div>
  );
};

const $btn =
  "bg-gradient-to-r from-[#F3E1A3] to-[#E3B354] w-full text-black text-[14px] font-[700]";
