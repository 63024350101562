import { history, useUserStore } from "src/stores";
import { useEffect, useState } from "react";
import { QUICK_MENU_LIST, MAIN_MENU_LIST } from "src/config/side-menu";
import { SideMenuList } from "./side-menu-list";
import { openSuccessNotification } from "./notification";
import { useTranslation } from "react-i18next";
import { ModalActivate } from "./modal-activate";

export const SideMenu = (props: any) => {
  const { t } = useTranslation();
  const [activeIds, setActiveIds] = useState<any>([]);
  const [user, setUser] = useState<any | null>(null);
  const [showActivateAccModal, setShowActivateAccModal] =
    useState<boolean>(false);

  const { logout, getProfileV2 } = useUserStore();

  useEffect(() => {
    getProfileV2().then((result) => {
      if (!result) return;
      setUser(result);
    });
  }, [getProfileV2]);

  const openExpandModel = (d: string, obj: any) => {
    let newActiveIdList: any = [];
    if (d === "down") {
      newActiveIdList = [...activeIds, obj.id];
    }
    if (d === "up") {
      newActiveIdList = activeIds.filter((id: any) => id !== obj.id);
    }

    setActiveIds(newActiveIdList);
  };

  const handleLogout = async () => {
    await logout();
    history.push("/");
    openSuccessNotification(t("message.logout_success"));
  };

  return (
    <div className="bg-[#000]/80 w-full lg:min-w-[350px] max-w-[60px] lg:max-w-[350px] flex flex-col min-h-screen justify-center lg:justify-start items-center lg:items-start p-[20px] lg:p-[30px] relative">
      {/* Logo */}
      <div
        className="cursor-pointer hidden lg:block"
        onClick={() => history.push("/dashboard")}
      >
        <img
          src="/assets/rich_dragon_horizontal_icon.png"
          className="w-[210px] object-cover"
          alt="rich-dragon-horizontal-icon"
        />
      </div>

      <div className="flex-1 w-full">
        {/* Quick Menu */}
        <div className="mt-[50px]">
          <p className={$menuLabel}>{t("quick_menu")}</p>
        </div>
        <div>
          <SideMenuList
            data={QUICK_MENU_LIST}
            activeIds={activeIds}
            openExpandModel={openExpandModel}
            setShowActivateAccModal={setShowActivateAccModal}
            user={user}
          />
        </div>

        {/* Main Menu */}
        <div className="mt-[0px] lg:mt-[25px]">
          <p className={$menuLabel}>{t("main_menu")}</p>
        </div>
        <div>
          <SideMenuList
            data={MAIN_MENU_LIST}
            activeIds={activeIds}
            openExpandModel={openExpandModel}
            setShowActivateAccModal={setShowActivateAccModal}
            user={user}
          />
        </div>
      </div>

      {/* Logout */}
      <p
        className="text-error cursor-pointer fixed bottom-[20px] lg:bottom-[30px] left-[20px] lg:left-[30px]"
        onClick={() => handleLogout()}
      >
        {t("logout")}
      </p>

      {/* Modals */}
      <ModalActivate
        isModalOpen={showActivateAccModal}
        setIsModalOpen={setShowActivateAccModal}
      />
    </div>
  );
};

const $menuLabel = "text-[12px] text-[#ccc] hidden lg:block";
