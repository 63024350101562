import { Button } from "./button";
import { RedoOutlined } from "@ant-design/icons";
import { LoadingIcon } from "./loading-icon";
import { useTranslation } from "react-i18next";

const { DatePicker } = require("antd");
const { RangePicker } = DatePicker;

interface IMasterCardTransactionFilterButtonsProps {
  setStartDate?: (val: string) => void;
  setEndDate?: (val: string) => void;
  getTransactionsCb: (params: { status?: string, type?: string, limit?: number, startDate?: string, endDate?: string }) => Promise<void>;
  onSearchClick: () => void;
  disabledButton: boolean;
  disabledRangePicker: boolean;
  isFetching: boolean;
  isSpin: boolean;
  setIsSpin: (val: boolean) => void;
  setIsLoading: (val: boolean) => void;
}

export const MasterCardTransactionFilterButtons = (props: IMasterCardTransactionFilterButtonsProps) => {
  const { t } = useTranslation();
  const {
    setStartDate,
    setEndDate,
    getTransactionsCb,
    onSearchClick,
    disabledButton,
    disabledRangePicker,
    isFetching,
    isSpin,
    setIsSpin,
    setIsLoading,
  } = props;

  const reload = async () => {
    setIsSpin(true);
    setIsLoading(true);
    await getTransactionsCb({});
    setIsSpin(false);
    setIsLoading(false);
    // if (!result) return;
  };

  const onRangeChange = (dates: null, dateStrings: string[]) => {
    if (!dateStrings.length) return;
    setStartDate && setStartDate(dateStrings[0]);
    setEndDate && setEndDate(dateStrings[1]);
  };

  return (
    <div className="mt-[10px] flex flex-row justify-between lg:items-center">
      <div>
        <Button
          value={t("button.reload")}
          btnStyle={disabledButton ? `bg-[#f5f5f5]` : ""}
          txtColorStyle="!text-[#000000A6]"
          suffix={<RedoOutlined spin={isSpin} className="pl-[10px]" />}
          handleOnClick={() => reload()}
          disabled={disabledButton}
        />
      </div>

      <div className="flex lg:flex items-center">
        <div className="master-card-ranger-picker lg:w-[290px] w-[260px] lg:ml-0 ml-[15px]">
          <RangePicker
            placeholder={[t("masterCard.start_date"), t("masterCard.end_date")]}
            className={inputClass}
            onChange={onRangeChange}
            disabled={disabledRangePicker}
          />
        </div>

        <div className="ml-[15px]" onClick={onSearchClick}>
          <button
            className={disabledButton ? $disabledBtn : $btn}
            disabled={disabledButton}
          >
            {isFetching ? <LoadingIcon size={"25"} /> : t("button.search")}
          </button>
        </div>
      </div>
    </div>
  );
};

const inputClass =
  "!py-[5px] bg-white !text-[10px] border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-primary focus:ring-primary w-full !rounded-[5px] sm:text-sm focus:ring-1 rounded-md";

const $btn = "px-[20px] py-[5px] bg-gradient-to-r from-[#F3E1A3] to-[#E3B354] text-black text-[12px] font-[700] min-w-[70px] w-full";
const $disabledBtn =
  "px-[20px] py-[5px] bg-[#f5f5f5] text-black text-[12px] font-[700] w-full cursor-not-allowed";
