import { useRef, useState } from "react";
import {
  openSuccessNotification,
  openWarningNotification,
} from "./notification";
import { useCardStore } from "src/stores";
import { LoadingIcon } from "./loading-icon";
import { MasterCardApplyCardFormBody } from "./master-card-applycard-form-body";
import { isBase64UrlImage } from "src/utils";
import { useTranslation } from "react-i18next";

export const MasterCardApplyCardForm = (props: any) => {
  const { t } = useTranslation();

  const {
    documentType = "",
    documentId = "",
    documentIdExpiryDate = "",
    setShowForm,
    getMasterCardStatusCb,
  } = props;
  const [faceImage, setFaceImage] = useState<string | null>("");
  const [backImage, setBackImage] = useState<string | null>("");
  const [passportImage, setPassportImage] = useState<string | null>("");
  const [signImage, setSignImage] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpLoading, setIsUpLoading] = useState<boolean>(false);
  const sigCanvasRef: any = useRef(null);

  const { applyMasterCard } = useCardStore();

  const handleSubmit = async (docType: string) => {
    if (isLoading) return;
    // Validate Form Fields
    if (
      faceImage &&
      backImage &&
      (!isBase64UrlImage(faceImage) || !isBase64UrlImage(backImage))
    )
      return openWarningNotification(t("message.image_format_error"));
    if (passportImage && !isBase64UrlImage(passportImage))
      return openWarningNotification(t("message.image_format_error"));
    if (signImage && !isBase64UrlImage(signImage))
      return openWarningNotification(t("message.image_format_error"));

    if (!signImage)
      return openWarningNotification(t("message.missing_sign_image"));

    if (docType === "HKID") {
      if (!faceImage || !backImage)
        return openWarningNotification(t("message.missing_id_image"));
      if (faceImage === backImage)
        return openWarningNotification(t("message.invalid_id_image"));
    }
    if (docType === "passport") {
      if (!passportImage)
        return openWarningNotification(t("message.missing_passport_image"));
    }

    setIsLoading(true);
    // Submit Application Form
    const result: any = await applyMasterCard({
      ...(faceImage && { faceImage }),
      ...(backImage && { backImage }),
      ...(passportImage && { passImage: passportImage }),
      signImage: signImage,
    });
    setIsLoading(false); // Reset Form
    if (!result || result !== "boolean")
      return openWarningNotification(t("message.submission_failed"));
    openSuccessNotification(t("message.submission_successful"));
    faceImage && setFaceImage("");
    backImage && setBackImage("");
    passportImage && setPassportImage("");
    signImage && setSignImage("");
    handleSignClear();
    setShowForm(false);
    getMasterCardStatusCb();
    return;
  };

  const handleSignSave = () => {
    const dataUrl = sigCanvasRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    if (!dataUrl) {
      openSuccessNotification(t("message.save_sig_failed"));
    }
    setSignImage(dataUrl);
    openSuccessNotification(t("message.save_sig_success"));
  };

  const handleSignClear = () => sigCanvasRef.current.clear();

  return (
    <div className="w-full lg:w-[545px] min-h-[100px] bg-white rounded-[10px] drop-shadow p-[20px]">
      <h3 className="normal-case">{t("masterCard.apply_form")}</h3>

      <MasterCardApplyCardFormBody
        documentId={documentId}
        documentType={documentType}
        documentIdExpiryDate={documentIdExpiryDate}
        faceImage={faceImage}
        backImage={backImage}
        passportImage={passportImage}
        isUpLoading={isUpLoading}
        setFaceImage={setFaceImage}
        setBackImage={setBackImage}
        setPassportImage={setPassportImage}
        setIsUpLoading={setIsUpLoading}
        sigCanvasRef={sigCanvasRef}
        handleSignSave={handleSignSave}
        handleSignClear={handleSignClear}
      />

      <div className="mt-[70px]" onClick={() => handleSubmit(documentType)}>
        <button className={$btn}>
          {isLoading ? <LoadingIcon size={"25"} /> : t("button.submit")}
        </button>
      </div>
    </div>
  );
};
const $btn =
  "bg-gradient-to-r from-[#F3E1A3] to-[#E3B354] w-full text-black text-[14px] font-[700]";
