import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { LoadingIcon } from "./loading-icon";
import { ModalConfirmConvertRow } from "./modal-confirm-convert-row";
import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Utils from "../utils";

export const ModalConfirmConvert = (props: any) => {
  const { t } = useTranslation();
  const {
    isLoading,
    modalOpen,
    handleClose,
    handleConfirm,
    fromCurrency,
    toCurrency,
    fromAmount,
    toAmount,
    exchangeRate,
    latestExchangeTime,
    timeCount,
  } = props;
  return (
    <Modal
      title={``}
      visible={modalOpen}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
      centered={true}
      className="rounded-xs"
    >
      <CloseButton handleCancel={handleClose} disabled={isLoading} />

      <div className="mx-auto flex flex-col justify-start align-start lg:w-[350px] min-h-[220px]">
        <div>
          <h3 className="my-lg text-center normal-case">
            {t("confirmation_exchange")}
          </h3>
        </div>

        <ModalConfirmConvertRow
          label="from"
          value={fromCurrency}
          amount={fromAmount}
        />

        <div className="mt-lg flex justify-center rotate-90">
          <img
            src={"/assets/swap.png"}
            className="w-[30px] h-[30px] object-cover"
            alt="token-icon"
          />
        </div>

        <div className="mt-m">
          <ModalConfirmConvertRow
            label="to"
            value={toCurrency}
            amount={toAmount}
          />
        </div>
        {isLoading && (
          <div className="w-full">
            <LoadingIcon size={"35"} color="#E3B354" />
          </div>
        )}

        {!isLoading && (
          <>
            <div className="mt-s flex flex-row justify-between items-center">
              <p className="text-s text-content">
                {t("exchange_rate_info")}
              </p>
              <p className="text-s text-content">
                {`1 ${Utils.getTokenLabel(fromCurrency)} : ${Number(
                  exchangeRate
                ).toFixed(2)} ${toCurrency}`}
              </p>
            </div>
            {latestExchangeTime && (
              <p className="label !text-[8px] text-right">
                {moment(latestExchangeTime).format("DD MMM YYYY hh:mm:ss")}
              </p>
            )}
            {timeCount > 0 && (
              <p className="mt-xs text-s text-primary text-center">
                {t("exchange_rate_validity", { timeCount })}
              </p>
            )}
          </>
        )}
      </div>

      <div
        className="mx-auto mt-lg lg:w-[350px]"
        onClick={() => handleConfirm()}
      >
        <button
          className={isLoading ? `button-gradient cursor-not-allowed` : "button-gradient"}
          disabled={isLoading}
        >
          {isLoading ? <LoadingIcon size={"25"} /> : t("button.confirm")}
        </button>
      </div>
    </Modal>
  );
};

