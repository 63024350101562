import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationForm } from "./application-form";
import { openErrorNotification, openSuccessNotification } from "./notification";
import { history, useRouterStore, useUserStore } from "src/stores";
import * as Utils from "../utils";
import moment from "moment";
import { LoadingIcon } from "./loading-icon";

export interface IApplicationPanelProps {
  username: string;
  gender: string;
  lastName: string;
  firstName: string;
  lastNameTc: string;
  firstNameTc: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string | number;
  country: string;
  countryCode: string;
  phoneNum: string;
  dob: string;
  email: string;
  position: string;
  occupation: string;
  income: string;
  fundsSource: string;
  documentType: string;
  nationality: string;
  documentId: string;
  documentIdExpiryDate: string;
}

export const ApplicationPanel = (props: any) => {
  const { t } = useTranslation();
  const { registerAccount } = useUserStore();
  const router = useRouterStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<IApplicationPanelProps>({
    username: "",
    gender: "",
    lastName: "",
    firstName: "",
    lastNameTc: "",
    firstNameTc: "",
    // Address
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    countryCode: "",
    phoneNum: "",
    dob: "",
    email: "",
    position: "",
    occupation: "",
    income: "",
    fundsSource: "",
    nationality: "",
    documentType: "",
    documentId: "",
    documentIdExpiryDate: "",
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    // Validate Form Value
    const validate: boolean = Utils.validateFormData(formData);
    if (!validate) return setIsLoading(false);

    const formattedDoB = moment(formData.dob, "MM-DD-YYYY").format(
      "DD MMM YYYY"
    );
    const formattedDocIdExpiryDate = moment(
      formData.documentIdExpiryDate,
      "MM-DD-YYYY"
    ).format("DD MMM YYYY");

    const payload: IApplicationPanelProps = {
      username: formData.username,
      gender: formData.gender,
      lastName: formData.lastName,
      firstName: formData.firstName,
      lastNameTc: formData.lastNameTc,
      firstNameTc: formData.firstNameTc,
      addressLine1: formData.addressLine1,
      ...(formData.addressLine2 && { addressLine2: formData.addressLine2 }),
      city: formData.city,
      state: formData.state,
      zipCode: String(formData.zipCode),
      country: formData.country,
      countryCode: formData.countryCode,
      phoneNum: formData.phoneNum,
      dob: formattedDoB,
      email: formData.email,
      position: formData.position,
      occupation: formData.occupation,
      income: formData.income,
      fundsSource: formData.fundsSource,
      documentType: formData.documentType,
      nationality: formData.nationality,
      documentId: formData.documentId,
      documentIdExpiryDate: formattedDocIdExpiryDate,
    };

    const result: any = await registerAccount(payload);
    setIsLoading(false);
    if (!result || typeof result !== "boolean")
      return openErrorNotification(t("message.submission_failed"));
    openSuccessNotification(t("message.submission_successful"));
    // Redirect to login
    return router.push("/");
  };

  return (
    <div className="w-full lg:w-[50vw] p-[20px] h-screen bg-white flex flex-col items-start justify-center overflow-y-auto">
      <div className="w-full lg:w-[550px] mx-auto text-left">
        <div>
          <div className="flex flex-col items-center justify-center bg-[#EFEFEF] px-s py-xxs">
            <p className="title">{t("form_header")}</p>
            <a
              className="font-[600] no-underline text-link cursor-pointer"
              href="https://trust.richdragon.io"
            >
              https://trust.richdragon.io
            </a>
          </div>

          <div className="mt-[45px]">
            <h2 className="normal-case">{t("apply_now")}</h2>
          </div>
          {/* Register Account Form */}
          <div className="mt-m">
            <ApplicationForm formData={formData} setFormData={setFormData} />
            <div className="mt-l mb-m mx-m">
              <button
                className="gradient"
                onClick={() => handleSubmit()}
                disabled={isLoading}
              >
                {isLoading ? <LoadingIcon size={25} /> : t("button.submit")}
              </button>
              <button
                className={$cancelBtn}
                onClick={() => history.push("/")}
                disabled={isLoading}
              >
                {t("button.goBack")}
              </button>
            </div>

            <div className="flex flex-col items-start">
              <div className="flex flex-row">
                <span className="mx-xxs text-xs">&#8226;</span>
                <p className="label">{t("apply_note_1")}</p>
              </div>
              <div className="flex flex-row">
                <span className="mx-xxs text-[10px]">&#8226;</span>
                <p className="label">{t("apply_note_2")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const $cancelBtn = "bg-white border-s text-black text-m w-full mt-s";
