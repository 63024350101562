import { useUserStore } from "src/stores";
import { LoginUsernameForm } from "./login-username-form";
import { LoginPasswordForm } from "./login-password-form";
import { useState } from "react";
import { openWarningNotification } from "./notification";
import { history } from "src/stores";
import { useTranslation } from "react-i18next";
const md5 = require("md5");

export const LoginRightPanel = (props: any) => {
  const { t } = useTranslation();

  const { stage, setStage } = props;

  const [username, setUsername] = useState<string | null>("");
  const [password, setPassword] = useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { checkUsername, login } = useUserStore();

  const handleCheckUsername = async () => {
    if (!username)
      return openWarningNotification(t("message.missing_username"));
    setLoading(true);
    const result = await checkUsername(username);
    setLoading(false);
    if (!result) return;
    setStage("password");
  };

  const handleLogin = async () => {
    if (!password)
      return openWarningNotification(t("message.missing_password"));
    setLoading(true);
    const result = await login({ username: username, password: md5(password) });
    setLoading(false);
    if (!result || !result["success"]) return;
    const { user } = result && result["data"];
    if (!user["force_pwd_change"]) return history.push("/dashboard");
    return history.push("/update-password");
  };

  return (
    <div className="w-full lg:w-[50vw] p-lg h-[auto] lg:h-screen bg-white flex flex-col items-start justify-center">
      <div className="w-full lg:w-[450px] mx-auto text-left">
        {/* Inner Container */}
        <div>
          <div className="flex flex-col items-center justify-center bg-rd-label-bg px-m py-s">
            <p className="login-label">{t("form_header")}</p>
            <a
              className="font-[600] no-underline text-[#274673] hover:!text-[#274673]"
              href="https://trust.richdragon.io"
            >
              https://trust.richdragon.io
            </a>
          </div>

          <div className="mt-xl">
            <h2 className="normal-case">{t("login")}</h2>
          </div>

          <p className="text-content mt-m">{t("login_to_account")}</p>
          <div className="mt-l">
            {stage === "username" && (
              <LoginUsernameForm
                username={username}
                setUsername={setUsername}
                handleCheckUsername={handleCheckUsername}
                loading={loading}
              />
            )}
          </div>

          {stage === "password" && (
            <LoginPasswordForm
              setStage={setStage}
              password={password}
              setPassword={setPassword}
              handleLogin={handleLogin}
              loading={loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};
