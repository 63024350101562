import {
  PieChartOutlined,
  CreditCardOutlined,
  LineChartOutlined,
  DollarOutlined,
  ReconciliationOutlined,
  UserOutlined,
  TransactionOutlined,
  MoneyCollectOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { ISideMenu } from "src/types/sideMenu";

const $labelIcon = "text-[20px] !text-[#fff]";

export const QUICK_MENU_LIST: ISideMenu[] = [
  {
    id: "fiat_wallet",
    name: "main_menu_item.onshore_wallet",
    icon: <WalletOutlined className={$labelIcon} />,
    page: "/wallet",
    subMenu: [],
  },
  {
    id: "asset_2",
    name: "main_menu_item.master_card",
    icon: <CreditCardOutlined className={$labelIcon} />,
    page: "/master-card",
    subMenu: [],
  },
  {
    id: "account_3",
    name: "quick_menu_item.convert",
    icon: <TransactionOutlined className={$labelIcon} />,
    page: "/convert",
    subMenu: [],
  },
];

export const MAIN_MENU_LIST: ISideMenu[] = [
  {
    id: "asset_1",
    name: "main_menu_item.asset_summary",
    icon: <PieChartOutlined className={$labelIcon} />,
    page: "/dashboard",
    subMenu: [],
  },
  {
    id: "asset_3",
    name: "main_menu_item.digital_asset",
    icon: <MoneyCollectOutlined className={$labelIcon} />,
    page: "/crypto-asset",
    subMenu: [],
  },
  {
    id: "asset_4",
    name: "main_menu_item.securities",
    icon: <LineChartOutlined className={$labelIcon} />,
    page: "/securities",
    subMenu: [],
  },
  {
    id: "asset_5",
    name: "main_menu_item.bill_payment",
    icon: <DollarOutlined className={$labelIcon} />,
    page: null,
    subMenu: [],
  },
  {
    id: "asset_6",
    name: "main_menu_item.order_records",
    icon: <ReconciliationOutlined className={$labelIcon} />,
    page: null,
    subMenu: [],
  },
  {
    id: "asset_7",
    name: "main_menu_item.account_center",
    icon: <UserOutlined className={$labelIcon} />,
    page: "/update-profile",
    subMenu: [],
  },
];
