import { Container, LoadingIcon } from "../components";
import { DashboardTransactionList } from "src/components/dashboard-transaction-list";
import { DashboardTxnStat } from "src/components/dashboard-txn-stat";
import { DashboardBasicManualFn } from "../components/dashboard-basic-manual-function";
import { Auth } from "src/components/auth";
import { useUserStore } from "src/stores";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalActivate } from "src/components/modal-activate";

export const Dashboard = (props: any) => {
  const { t } = useTranslation();

  const [user, setUser] = useState<any | null>(null);
  const [balance, setBalance] = useState<number | null>(null);
  const [transactions, setTransactions] = useState<any | null>(null);
  const [allLoaded, setAllLoaded] = useState(false);
  const [showActivateAccModal, setShowActivateAccModal] =
    useState<boolean>(false);

  const { getProfileV2, getProfileBalanceV2, getTransactions } = useUserStore();

  const getTransactionsCb = useCallback(
    async (filter: any) => {
      const result = await getTransactions(filter || {});
      if (!result || typeof result !== "object") return setTransactions([]);
      setTransactions(result);
    },
    [getTransactions]
  );

  useEffect(() => {
    getProfileV2().then((result) => {
      if (!result) return;
      setUser(result);
    });

    getProfileBalanceV2().then((result: any) => {
      if (!result) return;
      setBalance(result);
    });

    getTransactionsCb({});
  }, [getProfileV2, getProfileBalanceV2, getTransactionsCb]);

  useEffect(() => {
    if (!user) return;
    if (!balance) return;
    if (!transactions) return;
    setAllLoaded(true);
  }, [user, balance, transactions, setAllLoaded]);

  return (
    <Auth>
      <Container showNav={true} showMenu={true} scroll={true}>
        {!allLoaded && (
          <div className="flex flex-1 h-screen w-full justify-center items-center">
            <LoadingIcon color="#E3B354" />
          </div>
        )}

        {allLoaded && (
          <div className="flex flex-col p-m lg:p-l lg:pt-[100px] ">
            <div>
              <h2 className="normal-case">
                {t("dashboard.greeting", {
                  userDisplayName: user?.display_name || user?.username,
                })}
              </h2>
              <p className="text-s text-content mt-xs">
                {t("dashboard.welcome_back_message")}
              </p>
            </div>

            {/* Dashboard Components */}
            <div className="mt-m flex flex-col lg:flex-row">
              <div className="flex flex-2 mr-0 lg:mr-m">
                <DashboardTxnStat balance={balance} />
              </div>

              <div className="flex-1">
                <div className="mt-m lg:mt-0">
                  <DashboardBasicManualFn
                    user={user}
                    setShowActivateAccModal={setShowActivateAccModal}
                  />
                </div>
                <div className="mt-m">
                  <DashboardTransactionList
                    transactions={transactions}
                    getTransactionsCb={getTransactionsCb}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modals */}
        <ModalActivate
          isModalOpen={showActivateAccModal}
          setIsModalOpen={setShowActivateAccModal}
        />
      </Container>
    </Auth>
  );
};
