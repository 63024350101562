import { useTranslation } from "react-i18next";
import { LoadingIcon } from "./loading-icon";
import { history } from "src/stores";

export const UpdatePasswordForm = (props: any) => {
  const { t } = useTranslation();
  const {
    loading,
    password,
    setPassword,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    handleConfirm,
  } = props;

  return (
    <div className="mt-[45px]">
      <div>
        <p className="content">{t("old_password")}</p>
        <input
          className="text-input drop-shadow"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target?.value)}
        />
      </div>

      <div className="mt-s">
        <p className="content">{t("new_password")}</p>
        <input
          className="text-input drop-shadow"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target?.value)}
        />
      </div>

      <div className="mt-s">
        <p className="content">{t("confirm_new_password")}</p>
        <input
          className="text-input drop-shadow"
          type="password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target?.value)}
        />
      </div>

      <div className="mt-l">
        <button className="button-gradient" onClick={() => handleConfirm()}>
          {loading ? <LoadingIcon size={"25"} /> : t("button.confirm")}
        </button>
      </div>

      <div className="mt-s">
        <button className="button-secondary" onClick={() => history.push("/")}>
          {t("button.goBack")}
        </button>
      </div>
    </div>
  );
};
