import { useState } from "react";
import { Container } from "../components";
import { LoginLeftPanel } from "src/components/login-left-panel";
import { LoginRightPanel } from "src/components/login-right-panel";

export const Home = (props: any) => {
  const [stage, setStage] = useState("username"); // username -> password

  return (
    <Container showNav={false}>
      <div className="flex flex-col lg:flex-row">
        {/* Left Col - Banner, change lang & desc */}
        <LoginLeftPanel />
        {/* Right Col - Login */}
        <LoginRightPanel stage={stage} setStage={setStage} />
      </div>
    </Container>
  );
};
