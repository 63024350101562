import { UploadModal } from "./upload-modal";
import { MasterCardUploadButton } from "./master-card-upload-button";
import { useTranslation } from "react-i18next";

export const UploadId = (props: any) => {
  const { t } = useTranslation();

  const {
    faceImage,
    backImage,
    isUpLoading,
    setFaceImage,
    setBackImage,
    setIsUpLoading,
  } = props;

  return (
    <div className="flex mt-[20px]">
      <div className="min-w-[250px] flex-1 pr-[30px]">
        <p className="content">{t("masterCard.id_face_image")}</p>
        <div
          className={`px-[10px] py-[5px] mt-[5px] border border-#e5e7eb bg-[#fafafa] h-[220px]`}
        >
          <p className={`text-[#000`}>{t("masterCard.id_face_image")}</p>
          <p className={`content break-word`}>
            {t("masterCard.document_id_required")}
          </p>
          <div className="master-card-upload rounded-[5px] drop-shadow-2xl overflow-hidden m-auto mt-[10px] w-[150px] h-[120px] border border-[#E3B354]">
            <UploadModal
              setData={setFaceImage}
              setIsUpLoading={setIsUpLoading}
              title={t("masterCard.id_face_image")}
            >
              {!faceImage && (
                <MasterCardUploadButton isUpLoading={isUpLoading} />
              )}

              {faceImage && (
                <img
                  src={faceImage}
                  alt="id-front"
                  style={{ width: "100%" }}
                  className="object-cover"
                />
              )}
            </UploadModal>
          </div>
        </div>
      </div>

      <div className="min-w-[250px] flex-1">
        <p className="content">{t("masterCard.id_back_title")}</p>
        <div
          className={`px-[10px] py-[5px] mt-[5px] border border-#e5e7eb bg-[#fafafa] h-[220px]`}
        >
          <p className={`text-[#000]`}>{t("masterCard.id_back_image")}</p>
          <p className={`content break-word`}>
            {t("masterCard.document_id_required")}
          </p>
          <div className="master-card-upload rounded-[5px] drop-shadow-2xl overflow-hidden m-auto mt-[10px] w-[150px] h-[120px] border border-[#E3B354]">
            <UploadModal
              setData={setBackImage}
              setIsUpLoading={setIsUpLoading}
              title={t("masterCard.id_back_title")}
            >
              {!backImage && (
                <MasterCardUploadButton isUpLoading={isUpLoading} />
              )}

              {backImage && (
                <img
                  src={backImage}
                  alt="id-back"
                  style={{ width: "100%" }}
                  className="object-cover"
                />
              )}
            </UploadModal>
          </div>
        </div>
      </div>
    </div>
  );
};

