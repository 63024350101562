import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { useTranslation } from "react-i18next";
import { BankDetails } from "./bank-details";

interface IModalBankDetails {
  isLoading: boolean;
  modalOpen: boolean;
  currency: string;
  reference: string;
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ModalBankDetails = (props: IModalBankDetails) => {
  const { t } = useTranslation();
  const {
    isLoading,
    modalOpen,
    currency,
    reference,
    handleClose,
    handleConfirm,
  } = props;

  const getBankType = (currency: string) => {
    if (currency === "HKD") return "local";
    return "international";
  };

  return (
    <Modal
      title={``}
      visible={modalOpen}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
      centered={true}
      className="rounded-xs"
    >
      <CloseButton handleCancel={handleClose} />
      <div className="flex flex-col justify-start align-start min-h-[220px]">
        <div>
          <h3 className="my-m text-center normal-case">
            Rich Dragon {t("onshore_wallet.bank_info")}
          </h3>
          {/* Bank Info */}
          <BankDetails
            isLoading={isLoading}
            handleClose={handleClose}
            handleConfirm={handleConfirm}
            reference={reference}
            bankType={getBankType(currency)}
          />
        </div>
      </div>
    </Modal>
  );
};
